import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs/internal/Observable';
import { Router } from '@angular/router';
import { UserService } from '../services/userService';
import { ApiService } from '../services/api-service.service';
import { Subject } from 'rxjs';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { HostListener } from '@angular/core';

declare global {
  interface Window { API: any; }
}

window.API =  window.API || {};
@Injectable({
  providedIn: 'root'
})
export class ScormService {
  userId: any;
  userCourseId: any;
  resultKey: string;
  objectSORM: any = {};


  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler(event: Event) {
    event.preventDefault();
    // event.returnValue = false;
    window.alert('Are you sure you want to leave this page?');
  }

  errorDefinition = {
    0: {
      errorString: `No Error`,
      diagnostic: `No error occurred, the previous API call was successful.`
    },
    101: {
      errorString: `General Exception`,
      diagnostic: `No specific error code exists to describe the error.`
    },
    201: {
      errorString: `Invalid argument error`,
      diagnostic: `An argument represents an invalid data model element or is otherwise incorrect.`
    },
    202: {
      errorString: `Element cannot have children`,
      diagnostic: `LMSGetValue was called with a data model element name
      that ends in “_children” for a data model element that does not support the “_children” suffix.`
    },
    203: {
      errorString: `Element not an array. Cannot have count`,
      diagnostic: `LMSGetValue was called with a data model element name
      that ends in “_count” for a data model element that does not support the “_count” suffix.`
    },
    301: {
      errorString: `Not initialized`,
      diagnostic: `An API call was made before the call to LMSInitialize.`
    },
    401: {
      errorString: `Not implemented error`,
      diagnostic: `The data model element indicated in a call to LMSGetValue or LMSSetValue is valid,
      but was not implemented by this LMS. SCORM 1.2 defines a set of data model elements as being optional for an LMS to implement.`
    },
    402: {
      errorString: `Invalid set value, element is a keyword`,
      diagnostic: `LMSSetValue was called on a data model element
      that represents a keyword (elements that end in "_children" and "_count").`
    },
    403: {
      errorString: `Element is read only`,
      diagnostic: `LMSSetValue was called with a data model element that can only be read.`
    },
    404: {
      errorString: `Element is write only`,
      diagnostic: `LMSGetValue was called on a data model element that can only be written to.`
    },
    405: {
      errorString: `Incorrect Data Type`,
      diagnostic: `LMSSetValue was called with a value that is not consistent
      with the data format of the supplied data model element.`
    },
  };
  lmsFinishedFlag = false;


  private lmsFinisheEvent = new Subject();
  readonly lmsFinisheEvent$ = this.lmsFinisheEvent.asObservable();
  isModuleS1: any;

  constructor(private httpClient: HttpClient, private router: Router, private apiService: ApiService, private userService: UserService, public ngxService: NgxUiLoaderService) {
    this.userCourseId = localStorage.getItem('userCourseId')

  }

  setupAPI() {
    window.API.LMSInitialize = this.LMSInitialize;
    window.API.LMSGetValue = this.LMSGetValue;
    window.API.LMSSetValue = this.LMSSetValue;
    window.API.LMSCommit = this.LMSCommit;
    window.API.LMSFinish = this.LMSFinish;
    window.API.LMSGetLastError = this.LMSGetLastError;
    window.API.LMSGetDiagnostic = this.LMSGetDiagnostic;
    window.API.LMSGetErrorString = this.LMSGetErrorString;
    window.API.callMe = this.callMe;
    window.API.httpClient = this.httpClient;

    this.lmsFinishedFlag = false;

  }

  LMSInitialize() {
    this.lmsFinishedFlag = false;
    return true;
  }

  LMSFinish() {
    this.lmsFinishedFlag = true;
    // this.router.navigate(['/user/lms-courses']);
    const commitValue = this.LMSCommit(true, false);
    this.lmsFinisheEvent.next(true);
    return commitValue;
  }

  LMSSetValue(element, value) {

    localStorage.setItem(element, value);

    // this.LMSCommit()

    return localStorage.getItem(element);
  }



  LMSCommit(flagToCommitAndClose = false, onlogOut = false, window: Window = null) {
    // debugger
    const userObject = JSON.parse(sessionStorage.getItem('userObject'));
    const userCourseId = localStorage.getItem('userCourseId');
    const userCourseSessionId = localStorage.getItem('userCourseSessionId');

    if (onlogOut === true && (!userCourseId || !userObject)) {

      this.userService.removeSessionDetails();
      this.router.navigate(['/login']).then(_ => {
        window.close();
      });

    }


    if (userObject && userCourseId) {
      // console.log("userObject",userObject)

      const today = new Date();
      this.resultKey = 'cmi.interactions.' + localStorage.getItem('cmi.interactions._count') + '.result';
      const status = localStorage.getItem('cmi.core.lesson_status');

      const langObj = {
        id: localStorage.getItem('languageToStartModule')
      };

      // lms specific code
      const isComplete = status && status.toLowerCase() === 'completed' ? true : false;
      let moduleName = localStorage.getItem('cmi.comments');
      let isModuleM1 = false;
      let isModuleS1 = false;
      let isModuleE1 = false;

      if (typeof moduleName === 'string'){

        moduleName = moduleName.trim();

        isModuleM1 = moduleName && moduleName.includes('M1') ? true : false;
        isModuleS1 = moduleName && moduleName.includes('S1') ? true : false;
        isModuleE1 = moduleName && moduleName.includes('E1') ? true : false;
        if (moduleName.startsWith('|')) {
          // slice: ignore 0 and take string from first index
          moduleName = moduleName.slice(1);
          moduleName = moduleName.trim();
        }
      }



      if ((isComplete || isModuleM1 || isModuleS1 || isModuleE1 || this.lmsFinishedFlag === true || flagToCommitAndClose === true || onlogOut === true )) {
       
        // this.ngxService.start();
        if (userCourseSessionId) {
          const param = 'userCourseLMS/' + userCourseId + '/tracking';
          this.objectSORM = {
            status, //
            action: 'Browse',
            location: '',
            timing: today,
            courseType: 'VR',
            durationSpent: '',
            isSubmitted : isModuleS1 ,
            linkForRefferance: '',
            result: localStorage.getItem(this.resultKey),
            score: Number(localStorage.getItem('cmi.core.score.raw')),
            initialized: true,
            studentName: '',
            sessionTime: localStorage.getItem('cmi.core.session_time'),
            interactionCount: Number(localStorage.getItem('cmi.interactions._count')),
            objectiveCount: Number(localStorage.getItem('cmi.objective._count')),
            userCourseSessionId: localStorage.getItem('userCourseSessionId'),
            // courseModuleName: localStorage.getItem('cmi.comments'), //<-------------
            moduleName,
             // <-------------
            scormData: JSON.stringify(localStorage)
          };

          this.objectSORM.courseModuleLanguage = langObj;
          setTimeout(()=>{
            this.callMe(param, this.objectSORM).subscribe(
              result => {
                if (isModuleS1 === true){
                  sessionStorage.setItem('finalscore', result.data.score);
                  localStorage.setItem('finalscore', result.data.score);
                  // localStorage.setItem('finalscore', '1001');
                }
                if (isModuleE1 === true){
                  sessionStorage.removeItem("finalscore");
                  localStorage.setItem('cmi.comments', null);
                  // localStorage.setItem('finalscore', '1001');
                }
                // this.ngxService.stop()
                console.log("data of result",result)
                if (result != null) {
                  localStorage.setItem('userCourseSessionId', result.data.userCourseSessionId);
                }

                if (onlogOut === true) {
                  if (window) {
                    window.close();
                  }

                  this.apiService.get(`logOut/${userObject.userId}`).subscribe(_ => {
                    this.userService.removeSessionDetails();
                    this.router.navigateByUrl('/login');
                  });

                } else if (flagToCommitAndClose === true) {
                  localStorage.clear();
                  window.close();
                  if(result.statusCode==200){
                    this.router.navigate(['/user/lms-courses']);
                  }

                  if (window) {
                    console.log('Window closed flagToCommitAndClose');
                    window.close();
                  }

                }

                if (this.lmsFinishedFlag === true) {
                  // alert()
                  console.log('Window closed lmsFinishedFlag');
                  localStorage.clear();

                  window.close();
                  if(result.statusCode==200){
                    this.router.navigate(['/user/lms-courses']);
                  }
                  if (window) {
                    window.close();
                  }

                }


              });
          },10)

        } else {
          // this.ngxService.start()
          console.log('userCourseSessionId', false);

          const param = 'userCourseLMS/' + userCourseId + '/tracking';

          this.objectSORM = {
            status,
            action: 'Browse',
            location: '',
            timing: today,
            courseType: 'Online Training',
            durationSpent: '',
            linkForRefferance: '',
            result: localStorage.getItem(this.resultKey),
            score: Number(localStorage.getItem('cmi.core.score.raw')),
            initialized: true,
            studentName: '',
            userCourseSessionId: '',
            isSubmitted: isModuleS1,
            sessionTime: '',
            interactionCount: 1,
            objectiveCount: 1,
            moduleName,
            scormData: JSON.stringify(localStorage)
          };

          this.objectSORM.courseModuleLanguage = langObj;

          this.callMe(param, this.objectSORM).subscribe((result) => {

              // this.ngxService.stop()
            if (result != null) {
              localStorage.setItem('userCourseSessionId', result.data.userCourseSessionId);
            }



            if (onlogOut === true) {
              if (window) {
                window.close();
              }

              this.apiService.get(`logOut/${userObject.userId}`).subscribe(_ => {
                this.userService.removeSessionDetails();
                this.router.navigateByUrl('/login');
              });

            } else if (flagToCommitAndClose === true) {


              if (window) {
                console.log('Window closed on back to courses');
                localStorage.clear();
                window.close();
              }

            }

            if (this.lmsFinishedFlag === true) {
              if(result.statusCode==200){
                this.router.navigate(['/user/lms-courses']);
              }
              localStorage.clear();

              window.close();

              if (window) {
                console.log('Before window close, when course window close from browser');
                window.close();
              }

            }



          }, _ => { });
        }
      }
    }


    return true;
  }

  LMSGetValue(element) {
    if (element === 'cmi.core.lesson_mode') {
    }

    if (element === 'cmi.interactions._count') {
      const intCount = Number(localStorage.getItem('cmi.interactions._count')) + 1;
      localStorage.setItem('cmi.interactions._count', intCount.toString());
    }

    return localStorage.getItem(element);
  }

  _isInitialized() {
    return 1;
  }

  LMSGetLastError() {
    let errors = JSON.parse(localStorage.getItem('errors'));
    errors = JSON.parse(errors);
    if (errors && errors.length > 0) {
      return errors.pop();
    }
    return '';
  }

  LMSGetDiagnostic() {
  }

  LMSGetErrorString() {
    return 1;
  }

  _setError(errorCode) {
    console.log('errorCode', errorCode);
  }




  callMe(...param: string[]): Observable<any> {
    const user = JSON.parse(sessionStorage.getItem('userObject'));
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user.id_token}`
      })
    };
    return this.httpClient.post(environment.backend + param[0], param[1], httpOptions);
  }



}
